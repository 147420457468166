import { template as template_d1c418555dbb4686b378e3306d380dca } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_d1c418555dbb4686b378e3306d380dca(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
