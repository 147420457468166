import { template as template_64c7e8e8ba74404caf5daca959e988ba } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_64c7e8e8ba74404caf5daca959e988ba(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
