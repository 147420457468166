import { template as template_f348ac91004c48f7a895b68d08b5c7ae } from "@ember/template-compiler";
const FKText = template_f348ac91004c48f7a895b68d08b5c7ae(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
