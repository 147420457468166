import { template as template_bef993bdce434b90b97317cf080cab4e } from "@ember/template-compiler";
const FKControlMenuContainer = template_bef993bdce434b90b97317cf080cab4e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
